import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import VueQrcodeReader from "vue-qrcode-reader";
import axiosInstance from './axios';
import Vuesax from 'vuesax'

import './assets/stylesheets/application.scss'
import 'material-icons/iconfont/material-icons.css';
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

Vue.prototype.$http = axiosInstance;

Vue.use(VueQrcodeReader);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
