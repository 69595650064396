<template>
  <div>
    <qrcode-stream @decode="onDecode"></qrcode-stream>
    <!-- <button @click="searchCode('panoleta_86_2022')">prueba</button> -->
  </div>
</template>

<script>

export default {

  components: {

},

methods:{
  onDecode (onDecode) {
    console.log('onDecode', onDecode)
    var data = this.validateQR(onDecode)
    if(data != false){
      this.searchCode(data[0])
      console.log('QR valido', data)

    }else{
      console.log('QR no valido')
      this.$vs.notify(
        {
          time:5000,
          title:'QR no válido, por favor escane solo los QR oficiales',
          color:'danger',
          position:'bottom-center'
        }
      )
    }
  },


  validateQR(qrText) {
      const patronQR = /^https:\/\/admin\.petline\.com\.co\/#\/panoleta\/panoleta_\d+_\d+$/;

      if (patronQR.test(qrText)) {
          const codeFormat = /panoleta_(\d+)_(\d+)/;
          const match = qrText.match(codeFormat);
          return match; // El QR es válido
      } else {
          return false; // El QR no sigue el patrón esperado
      }
  },

  searchCode(code){
    this.$vs.loading({
      type:'border',
    })
      this.$http.post('validarCodigoAccesorio',{
        codigo: code 
      })
      .then(response => {
        // Manejar la respuesta de la API
        console.log(response.data);
        this.$vs.loading.close()
        this.$vs.notify(
        {
          time:5000,
          title:'QR escaneado con éxito',
          color:'success',
          position:'bottom-center'
        }
      )
      this.$emit('scanSuccess', code);
      })
      .catch(error => {
        // Manejar errores
        this.$vs.loading.close()
        console.error('Error fetching data:', error);
        this.$vs.notify(
        {
          time:5000,
          title:'Error al validar el QR '+ error,
          color:'danger',
          position:'bottom-center'
        }
      )
      });
  }


}
}

</script>

<style>

</style>
