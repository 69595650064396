import Vue from 'vue'
import VueRouter from 'vue-router'

import IndexPanoletas from '../panoletas/indexPanoletas'
// import ScanQR from '../panoletas/scanQR'


Vue.use(VueRouter)
/*eslint-disable */
const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexPanoletas
  }
 
//   {
//     path: '/qr',
//     name: 'qr',
//     component: ScanQR
//   },

]

const router = new VueRouter({
  routes
})

export default router
