<template>
    <div >
      <!-- <img src="@/assets/img/background.jpg"> -->

      <div class="index_container">

        <div>
          <modalScan :show="showModal" @close="closeModal"></modalScan>

          
          <div class="index_container__logo_container">
            <img class="index_container__logo_container--logo" src="../assets/img/logo_petline.png" />
          </div>

          <h2 class="index_container__title">Registro de pañoletas</h2>

          <div class="index_container__pet_register">
            <label class="index_container__pet_register--title" for="nombreMascota">Nombre de mascota:</label>
            <input class="index_container__pet_register--input" type="text" id="nombreMascota" name="nombreMascota" v-model="pet">

            <label class="index_container__pet_register--title" for="nombreDueno">Nombre de responsable:</label>
            <input class="index_container__pet_register--input" type="text" id="nombreDueno" name="nombreDueno" v-model="name">

            <label class="index_container__pet_register--title" for="nombreDueno">Número de celular:</label>
            <input class="index_container__pet_register--input" type="number" id="nombreDueno" max="10" name="nombreDueno" v-model="phone">
            
            <button class="index_container__pet_register--button_scan" @click="scanQR">Escanear QR</button>
            
            <button class="index_container__pet_register--button_register" @click="sendForm()" :disabled="validScan === false">Registrar</button>

            
          </div>
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  import modalScan from './modalScan';
  export default {
    data(){
      return{
        showModal: false,
        validScan: false,
        code: '',
        pet: '',
        phone: '',
        name: ''
      }
    },
    
    components: {
        modalScan
      },   

    watch:{

      
      phone(){
        this.phone.replace(/\s/g, '');
      }

    }, 


    methods:{

      scanQR(){
        console.log('scan qr')
        this.showModal = true
       
      },
      closeModal(data) {
        console.log('closeModal', data)
        this.showModal = false;
        if (data){
          this.validScan = true
          this.code = data
        }
        
      },


      validForm(){

        if(this.pet == '' || this.phone == '' || this.name == ''){
         
          this.$vs.notify(
            {
              time:5000,
              title:'Debes completar todo el formulario',
              color:'danger',
              position:'bottom-center'
            }
          )
          return false
        }else if(this.code == ''){
          
          this.$vs.notify(
            {
              time:5000,
              title:'Debes escanear el QR de la pañoleta',
              color:'danger',
              position:'bottom-center'
            }
          )
          return false
        }else if(this.phone.toString().length < 10){
          this.$vs.notify(
            {
              time:5000,
              title:'Debes agregar un número de celular válido sin el indicativo',
              color:'danger',
              position:'bottom-center'
            }
          )
          return false
        }else{
          return true
        }

      },

      sendForm(){

        if(this.validForm()){

            this.$vs.loading({
              type:'border',
            })
              this.$http.post('vincularAccesorioWeb',{
                    codigo: this.code,
                    telefono: this.phone,
                    mascota: this.pet,
                    nombre: this.name
              })
              .then(response => {
                // Manejar la respuesta de la API
                console.log(response.data);
                this.code = ''
                this.pet = ''
                this.phone = ''
                this.name = ''
                this.$vs.loading.close()
                this.$vs.notify(
                {
                  time:5000,
                  title:'Registro exitoso',
                  color:'success',
                  position:'bottom-center'
                }
              )
              
              })
              .catch(error => {
                // Manejar errores
                this.$vs.loading.close()
                console.error('Error fetching data:', error);
                this.$vs.notify(
                {
                  time:5000,
                  title:'Error al validar el QR '+ error,
                  color:'danger',
                  position:'bottom-center'
                }
              )
              });
          }

    }

    }

  }
  </script>
  
  <style>

  </style>
  