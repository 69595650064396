<template>
    <div>
      
    
      <div v-show="show" class="modal_container">
        <div class="modal_container__content">
          
          <h3 class="modal_container__content--title">Escanea el código QR</h3> 
          <scanQR @scanSuccess="scanSuccess" ></scanQR>
 
          <span @click="closeModal" class="close">&times;</span>
          
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
/* eslint-disable */
  import scanQR from './scanQR';
  export default {
    props: ['show'],
    data() {
      return {
        showModal: true
      };
    },
    components: {
      scanQR
    },
    methods: {
      scanSuccess(data){
        this.$emit('close', data);
      },
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.$emit('close');
    }
 
    }

  };
  </script>
  
  <style>
 
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>